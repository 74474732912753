import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import debounce from 'lodash.debounce'

import './index.scss';

import Modal from '@material-ui/core/Modal';

function getModalStyle() {
    return {
        top: `100px`,
        left: `5%`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

class SimpleSearchModal extends React.Component {

    /*static async getInitialProps () {
        const apiUrl = 'https://www.meritmile.com/wp-json/wp/v2/';
        const params = 'multiple-post-type?search=Marketing&per_page=10&fields=title,excerpt,slug,acf,type&type[]=post&type[]=page';
        const res = await fetch(apiUrl + params)
        const data = await res.json()
        return { data }
    }*/

    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
        this.state = {
            open: false,
            data: []
        };
    }

    handleOpen() {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    get(url) {
        return fetch(url, {
            method: 'get'
        });
    }

    getJSON = (url) => {
        return this.get(url).then(function(response) {
            return response.json();
        });
    }

    fetchSearchTerm = searchTerm => {

        // Get input value - DONE
        // const searchTerm = document.getElementById('search').value;
        // console.log(searchTerm);

        // Make api call based on value - DONE
        const apiUrl = "https://apiold.meritmile.com";
        const params = `/wp-json/wp/v2/multiple-post-type?search=${searchTerm}&per_page=100&fields=title,excerpt,slug,acf,link,type&type[]=post&type[]=page`;

        // this.getJSON(apiUrl + params)
        // .then(function(data) {
        //     console.table(data);
        // });

        this.getJSON(apiUrl + params)
            .then(data => this.setState({data}));

        // Render results from api data - WORKING ON IT
        // see below inside search-results ul

    };

    // Get a new function that is debounced when called
    debouncedSearch = debounce(this.fetchSearchTerm, 700)

    /**
     * Called onSubmit event
     */
    formGetResults = e => {
        e.preventDefault()
        const { search } = e.target
        // unfocusing input makes soft keyboard to close
        window.outerWidth < 1024 && search.blur()
        // cancel any pending search
        this.debouncedSearch.cancel()
        this.fetchSearchTerm(search.value)
    }

    /**
     * Called onChange event
     */
    getSearchResults = e => {
        var { value } = e.target
        if (value.length < 3) return
        this.debouncedSearch(value)
    }

    nthIndex = (str, pat, n) => {
        const L= str.length;
        let i = -1;
        while(n-- && i++<L){
            i= str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    };

    render() {
        const { classes } = this.props;
        // console.log(this.state.data);
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}
                    className="searchModal"
                >
                    <div style={getModalStyle()} className={classes.paper+' modalBox'}>
                        <div className="container-fluid">
                            <div className="row searchboxrow">
                                <div className="input-field col-12">
                                    <form onSubmit={this.formGetResults}>
                                        <input placeholder="Search..." id="search" className="form-control" type="text" ref={this.textInput} onChange={this.getSearchResults} />
                                        <label htmlFor="search">Search</label>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="search-results">
                                        {this.state.data.map((post,i) => {
                                            if(this.state.data[i].type === 'post') {
                                                return(
                                                    <li key={i}>
                                                        <a href={'/news/' + this.state.data[i].slug}>
                                                            <h4>{this.state.data[i].title.rendered}</h4>
                                                            <div dangerouslySetInnerHTML={{ __html: this.state.data[i].excerpt.rendered}} />
                                                        </a>
                                                    </li>
                                                )
                                            }
                                            else {
                                                return(
                                                    <li key={i}>
                                                        <a href={this.state.data[i].link.slice(this.nthIndex(this.state.data[i].link ,'/',3))}>
                                                            <h4>{this.state.data[i].title.rendered}</h4>
                                                            <div dangerouslySetInnerHTML={{ __html: this.state.data[i].excerpt.rendered}} />
                                                        </a>
                                                    </li>
                                                )
                                            }

                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

SimpleSearchModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSearchModal);